import React from "react";
import { NavLink, Link } from "react-router-dom";
import Footer from "./Footer";
const About = () => (
  <div className="cont">
    <header>
      <div className="forword">
        <p>
          Shelley Motors was started as a father-son venture by Craig and Paul
          Shelley.
        </p>

        <p>
          Dr. Craig Shelley is a published PhD Chemist with research in the
          fields of spectroscopy and computerized chemical analysis. Having
          founded and sold a successful technology company he has since become
          fascinated with the changes facing the automotive landscape. Never in
          our lifetime has there been such a rapid development and infusion of
          technology and computer systems into automobiles. This rapid change
          has been difficult for the average car owner and enthusiast to keep
          up.
        </p>

        <p>
          Paul Shelley is the CEO of Shelley Motors, with a background in
          software engineering, and a long time automotive enthusiast. He
          founded the company from a love of cars and technology, wanting to
          better integrate the two. As a connoisseur of classic cars, he wanted
          to bring modern conveniences to these historical vehicles while
          maintaining their original heritage.
        </p>
      </div>
    </header>
    <section className="main"></section>
    <Footer />
  </div>
);

export default About;
