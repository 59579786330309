import React from "react";
import { NavLink } from "react-router-dom";
import Icon from "./Icon";
const Header = () => (
    <NavLink activeClassName="is-active" to="/" exact={true}>
      <div className="icon">
        <Icon />
      </div>
    </NavLink>
);

export default Header;
