import React from "react";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const ProductionManager = () => (
  <div className="cont">
    <section className="main-centered">
      <h1 className="job-title-detail">
        <span>Production Manager</span>
        <a href="mailto:jobs@shelleymotors.com?Subject=Jr.%20Production%Manager">
          <span className="job-btn">apply for this job</span>
        </a>
      </h1>
      <div className="job-tag">
        <span>Salt Lake City</span>
        <span>Manufacturing</span>
        <span>Full time</span>
      </div>
      <div className="job-description">
        <h3>The Role</h3>
        <p>
        The production manager is responsible for ensuring manufacturing runs smoothly and on time. This individual will work with both our in-house final assembly and production as well as working with external manufacturers for parts that are contracted. They will deploy inventory strategies, be responsible for product change, and associate material planning and implications. This role involves managing timelines and relationships with component and parts suppliers and coordinating these expectations within our organization for smooth operation. While some manufacturing as well as final assembly is done in-house this role is global in scope and requires cross-functional engagement across the extended supply chain. 
        </p>
        <h3>Responsibilities</h3>
        <ul>
          <li>
          Create and deploy supply requirements to the supply base via MRP which achieves production, inventory and cost objectives. Ensure demand from all sources (production, aftermarket, engineering, etc.) is supported appropriately.
          </li>
          <li> Manage planning system parameters and master data to optimally reflect supply chain capabilities and plan supply.</li>
          <li>Apply analytics and supply chain expertise to manage system parameters and meet target inventory and service level goals while minimizing costs and obsolescence.</li>
          <li>Build and maintain operational partnerships with assigned suppliers. Act as the primary interface with suppliers on delivery status, issues and resolution. Escalate to commercial or technical partners when required (GSM, accounts payable, quality, engineering, etc.).</li>
          <li>Support onboarding and training of new suppliers to ensure operational excellence and compliance with our supply chain processes and policies.</li>
          <li> Coordinate supplier ramp up and down for new product launches, ensuring suppliers deliver shipments needed to support production targets.</li>
          <li> Deliver clear-to-build status, material coverage, run-out reports and other analyses as needed. Provide data-driven decision support to the broader organization through what-if and scenario planning (as examples).</li>
          <li>Manage engineering changes to minimize obsolete inventory and ensure continuous supply.</li>
          <li>Partner with IT and other business stakeholders on planning process and system improvements.</li>
        </ul>
        <h3>Requirements</h3>
        <ul>
          <li>
          At least 5 years’ experience in manufacturing, or supply chain management.
          </li>
          <li> Bachelors or Masters in Supply Chain, Finance, Industrial Engineering or similar discipline. CPIM or APIC training is a plus.</li>
          <li>Strong knowledge of MRP/ERP with a solid understand of discrete manufacturing, bills of material, product lifecycle management, procure to pay processes and supply chain operations.</li>
          <li>Excellent analytical and problem-solving skills. Ability to evaluate and analyze data to enable operational decision-making or present clear recommendations to leadership.</li>
          <li>Demonstrated skills in supplier operational and relationship management.</li>
          <li> Passionate and high energy, with the diplomacy and cooperative style necessary to positively influence others.</li>
          <li>Ability to write, speak and present effectively and persuasively. 
          </li>
        </ul>
      </div>
    </section>
    <Footer />
  </div>
);

export default ProductionManager;
