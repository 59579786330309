import React from "react";

const Arrow = () => (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0" y="0" width="12" height="12" viewBox="0, 0, 150, 150">
    <g id="Background">
      <rect x="0" y="0" width="150" height="150" fill="#FFFFFF" fillOpacity="0"/>
    </g>
    <g id="Layer_1">
      <path d="M89.782,19.176 C85.73,15.124 79.133,15.124 75.041,19.216 C70.949,23.309 70.928,29.926 74.98,33.977 L105.561,64.558 L19.153,64.599 C13.391,64.538 8.688,69.241 8.749,75.003 C8.708,80.785 13.391,85.468 19.194,85.447 L105.581,85.468 L75,116.048 C70.949,120.1 70.928,126.717 75.021,130.809 C79.072,134.861 85.73,134.881 89.782,130.829 L138.136,82.393 C140.05,80.479 141.19,77.914 141.251,75.003 C141.251,72.152 140.05,69.526 138.177,67.653 L89.782,19.176 z" fill="#15191E"/>
    </g>
  </svg>
  )

  export default Arrow;