import React from "react";

const Icon = () => (
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0" y="0" width="65" height="65" viewBox="0, 0, 150, 150">
  <g id="Background">
    <rect x="0" y="0" width="65" height="65" fill="#FFFFFF" fillOpacity="0.0"/>
  </g>
  <g id="Layer_1">
    <g>
      <path d="M133.361,86.091 L133.36,86.091 L108.513,104.256 L117.949,133.592 C128.502,122.949 133.497,108.113 133.87,93.383 C133.757,88.926 133.895,91.358 133.361,86.091 L133.361,86.091 z" fill="#71B2C9"/>
      <path d="M75,16.408 L58.349,67.144 L75,118.39 L91.651,67.143 L75,16.408 z" fill="#F2D03E"/>
      <path d="M97.204,69.435 L107.03,99.678 L148.193,69.586 L97.204,69.435 z" fill="#F2D03E"/>
      <path d="M52.796,69.435 L1.807,69.585 L42.97,99.678 L52.796,69.435 z" fill="#F2D03E"/>
      <path d="M63.88,36.276 C46.862,39.48 31.793,50.356 23.517,65.594 L54.288,65.503 L63.88,36.276 z" fill="#F4633A"/>
    </g>
  </g>
</svg>
  )

  export default Icon;