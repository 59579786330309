import React from "react";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const JobBoard = () => (
  <div className="cont">
    <section className="main-centered">
      <h1>ENGINEERING</h1>
      <Link to="/jobs/embedded-engineer">
        <div className="job-link">
          <div>
            <div className="job-title">Jr. Embedded Engineer </div>
            <div className="job-tag">
              <span>Salt Lake City</span>
              <span>Engineering</span>
            </div>
          </div>
          <div className="job-btn">APPLY</div>
        </div>
      </Link>
      <Link to="/jobs/production-manager">
        <div className="job-link">
          <div>
            <div className="job-title">Production Manager</div>
            <div className="job-tag">
              <span>Salt Lake City</span>
              <span>Engineering</span>
            </div>
          </div>
          <div className="job-btn">APPLY</div>
        </div>
      </Link>
    </section>
    <Footer />
  </div>
);

export default JobBoard;
