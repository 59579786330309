import React from "react";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const EmbeddedEngineer = () => (
  <div className="cont">
    <section className="main-centered">
      <h1 className="job-title-detail">
        <span>Jr. Embedded Engineer</span>
        <a href="mailto:jobs@shelleymotors.com?Subject=Jr.%20Embedded%20Engineer">
          <span className="job-btn">apply for this job</span>
        </a>
      </h1>
      <div className="job-tag">
        <span>Salt Lake City</span>
        <span>Engineering</span>
        <span>Full time</span>
      </div>
      <div className="job-description">
        <h3>The Role</h3>
        <p>
        As a Jr. Embedded Engineer you will be responsible for delivering high quality embedded code running on the microcontrollers at the core of our products. You will work closely with our head of software as well as our electrical engineers to ensure feature compatibility and development of new products as well as support and improvements on existing products. This role has great growth opportunity to learn from experienced industry experts at a small yet quickly growing company that offers a wider variety of learning than what might be achieved at a large company with strictly defined roles.
        </p>
        <h3>Responsibilities</h3>
        <ul>
          <li>
            Deliver high-quality code in a real-time embedded environment.
          </li>
          <li>Specify, design, and implement functionality and behaviors of embedded subsystems.</li>
          <li>Design the software architecture and firmware implementation on hardware through integration, test and volume manufacturing.</li>
          <li>Hands-on hardware, system debugging and code optimization.</li>
          <li>Make performance and optimization trade-offs to meet product requirements.</li>
        </ul>
        <h3>Requirements</h3>
        <ul>
          <li>
          Bachelor's Degree in Computer Science, Computer/Electrical Engineering, or equivalent work experience. 
          </li>
          <li>Experience in embedded development in robotics, real-time controls, or system software.</li>
          <li>Proficiency in C or C family of languages, familiarity with assembly is a plus.</li>
          <li>Fluent in software fundamentals including software design and maintainability.</li>
          <li>Experience with the embedded microprocessor tools.</li>
          <li>Controls software and algorithm development.</li>
          <li>Test driven development and familiarity with developer driven automated testing.</li>
          <li>Ability to collaborate and communicate complex technical concepts.</li>
          <li>Understanding of physics and basic principles is highly preferred.</li>
          <li>Experience with any Cortex-M or Atmel microcontrollers a plus.</li>
        </ul>
      </div>
    </section>
    <Footer />
  </div>
);

export default EmbeddedEngineer;
