import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Header from '../components/Header';
import JobBoard from '../components/JobBoard';
import HomePage from '../components/HomePage';
import NotFoundPage from '../components/NotFoundPage';
import EmbeddedEngineer from '../components/EmbeddedEngineer';
import ProductionManager from '../components/ProductionManager';
import About from '../components/About';

const AppRouter = () => (
    <BrowserRouter>
    <div>
    <Header />
    <Switch>
        <Route path="/" component={HomePage} exact={true}/>
        <Route path="/job-board" component={JobBoard} />
        <Route path="/jobs/embedded-engineer" component={EmbeddedEngineer} />
        <Route path="/jobs/production-manager" component={ProductionManager} />
        <Route path="/about" component={About} />
        <Route component={NotFoundPage} />
    </Switch>
    </div>
    </BrowserRouter>
)

export default AppRouter;