import React from "react";
import { NavLink, Link } from "react-router-dom";
import Arrow from "./Arrow";
import Logo from "./Logo";
import Footer from "./Footer"
const HomePage = () => (
  <div className="cont">
    <header>
      <div className="logo">
        <Logo />
      </div>
      <div className="forword">
        <b>Welcome.</b>  Specializing in automotive tools and accessories, Shelley Motors was created to improve the ownership experience with modern technology and great user-focused design. We have a number of exciting products in the works that we are not quite ready to announce. If you would like to keep informed please subscribe to our email list. We are also looking for talented people to join our team.
      </div>
    </header>
    <section className="main">
      <div className="content-links">
        <a className="content-link" href="mailto:updates@shelleymotors.com?Subject=Keep%20Me%20Updated">
          <div>
            Be the first to know when we are ready to share what we've been
            working on.
          </div>
          <div className="cta" id="btn1">
            <span>get notified</span>
            <span>
              <Arrow />
            </span>
          </div>
          </a>
        <NavLink
          activeclassName="is-active"
          className="content-link"
          to="/about"
        >
          <div>
          Started by a father and son we value quality and safety.
          </div>
          <div className="cta" id="btn2">
            <span>about us</span>
            <span>
              <Arrow />
            </span>
          </div>
        </NavLink>
        <NavLink
          activeclassName="is-active"
          className="content-link"
          to="/job-board"
        >
          <div>
            If you love how technology can interact with the physical world,
            come join us.
          </div>
          <div className="cta" id="btn3">
            <span>job board</span>
            <span>
              <Arrow />
            </span>
          </div>
        </NavLink>
      </div>
    </section>
    <Footer />
  </div>
);

export default HomePage;
