import React from "react";

const Footer = () => (
    <footer>
    <div className="container wide">
      <div className="grid-flex baseline no-break">
        <div className="col hide-sm">
          <span className="h6 light-text-color">
            © <span className="hide-lg">Shelley Motors </span>2019
          </span>
        </div>
        <div className="col">
          <a
            className="h6 light-text-color"
            href="mailto:info@shelleymotors.com"
          >
          info@shelleymotors.com
          </a>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer;